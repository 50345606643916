import { Injectable } from '@angular/core';
import { ShopService } from '@laasy/common-layout/shop';
import { first, map, Observable, of } from 'rxjs';
import { BannerDetails, collectionType } from '../../utilities/constants';
import { Banner } from '../../models/landing';
import { CommonService } from '@laasy/common-layout';

@Injectable({
  providedIn: 'root'
})
export class LandingService {

  constructor(private shopApiService:ShopService,private commonService:CommonService) { }

  getTopMerchants(): Observable<any> {
    return this.shopApiService.getTopMerchants()
  }

  getTopBrands(): Observable<any> {
    return this.shopApiService.getTopBrands()
  }

  getCollections(collectionTyp: string): Observable<any[]> {
    const systemAndNonSystem$ = this.shopApiService.getCollections()
    const userPref$ = this.shopApiService.getUserPrefCollections()
    return (collectionType.user === collectionTyp) ? userPref$ : systemAndNonSystem$
      .pipe(
        map((resp: any[]) => {
          let collectionList: any[] = [];
          resp.forEach((item:any,i:number) => {
            if (item.isCard) {
              let lastEl = collectionList[collectionList?.length - 1];
              if (lastEl?.isCard) {
                lastEl.cardList.push(item);
              } else {
                collectionList.push({
                  isCard: true,
                  cardList: [item]
                });
              }
            } else {
              collectionList.push(item);
            }
          });
          return collectionList;
        }))
  }

  getBannerData(): Observable<Banner[]> {
    return this.commonService.getCustomization().pipe(first(), map(data => {
      const shopCustomization = JSON.parse(data?.data?.[0]?.shopCustomization ?? '[]')
      const transformedData = shopCustomization.map((x: any) => {
        return {
          prefix_text_underlined: '',
          post_text_underlined: x?.bannerTitle,
          image_url: x?.bannerImage
        }
      })
      if (!transformedData.length) return BannerDetails
      return transformedData
    }))
  }
}
