import { Injectable } from "@angular/core";
import { Observable, first, forkJoin, map } from "rxjs";
import { mapDataToSkuCard } from "@laasy/common-layout";
import { environment } from "src/environments/environment";
import { ShopService } from "@laasy/common-layout/shop";

@Injectable({
  providedIn: 'root'
})
export class CollectionResultService {


  constructor(private readonly shopApiService: ShopService) { }

  getCollectionData(type: string): Observable<any> {
    const systemAndNonSystem$ = this.shopApiService.getCollections().pipe(first())
    const userPref$ = this.shopApiService.getUserPrefCollections().pipe(first())
    return forkJoin([systemAndNonSystem$,userPref$]).pipe(
      map((data: any) => data[0].concat(data[1])),
      map((data: any) => data.find((x: any) => {
        const  collectionName = x.collectionName
        return collectionName === type
      })),
      map((data: any) => {
        const skus = data.skus
        let transformedSKUs: any = []
        skus.forEach((x: any) => {
          const transformedData = mapDataToSkuCard(x.sku)
          transformedSKUs.push({ ...transformedData, relevance: x.rank })
        });
        data.skus = transformedSKUs
        return data
      })
    )
  }
}
