import { Component, OnInit, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { DropShipOrderService } from 'src/app/shared/services/Page/drop-ship-order.service';
import { urlProtocol } from '@laasy/common-layout';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-post-purchase',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './post-purchase.component.html',
  styleUrls: ['./post-purchase.component.scss']
})
export class PostPurchaseComponent implements OnInit {

  readonly actRoute = inject(ActivatedRoute);
  readonly apiService = inject(DropShipOrderService);
  readonly routerService = inject(Router);

  OrderConfirmedMessage = {
    heading:'Your order is confirmed!',
    description:'Your cashback and purchase details will appear in your purchase history.'
  }
  message = {
    heading:'Please wait',
    description:'Please wait while we are processing the order. Do not close the window.'
  }
  isSuccess = false;
  orderNo!: number;

  async ngOnInit() {
    //close loader
    const loaderContainer = document.querySelector('.loader-container');
    if (loaderContainer) setTimeout(() => loaderContainer.remove(), 500);

    const order = this.actRoute.snapshot.queryParams['order'];
    this.orderNo = this.actRoute.snapshot.queryParams['order-no'];
    try {
      await this.apiService.Order(order)
      this.message = {... this.OrderConfirmedMessage}
      this.isSuccess = true;
    } catch (error) {
      this.message = {heading:'Something went wrong',description:`Something went wrong while confirming your cashback. Please reach out to support to make sure you get cashback for your purchase.`}
      console.error("Order failed to store in db. OrderNo ::",this.orderNo)
      console.error(error)
    }
  }

  openPurchaseHistory(){
    const HomeAppURL = urlProtocol(localStorage.getItem("HomeAppURL")||environment.redirectHomeBase)
    window.open(`${HomeAppURL}/account/purchasehistory`,'_self')
  }

  openSupport() {
    window.open('https://laasy.zendesk.com/hc/en-us/articles/25071778773659-Shop-FAQ#h_01HZM64772SGM2A11538TJAWF3', '_blank')
  }

}
