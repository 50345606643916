<app-base-swipe-banner [bannerDetails]="data" (updateBannerIndex)="updateBannerIndex($event)" *ngIf="isMobile">
  <div leftSection class="collections-text-container">
    <div>{{data[bannerIndex].heading}}</div>
    <div class="collections-text-link" (click)="navigate(data[bannerIndex].link)">{{data[bannerIndex].ctaText}}</div>
  </div>
  <div rightSection class="collections-img-container" [ngStyle]="getStyle(data)">
  </div>
</app-base-swipe-banner>

<div *ngIf="!isMobile" class="collection-cards-container">
  <div class="collection-card-container" *ngFor="let itm of data" [ngStyle]="{'background':itm.color}">
    <div class="collection-card-left-section collections-text-container">
      <div class="collection-card-txt">{{itm.heading}}</div>
      <div class="collections-text-link" (click)="navigate(itm.link)">{{data[bannerIndex].ctaText}}</div>
    </div>
    <div class="collection-card-right-section collections-img-container"><img class="img-alignment" [src]="itm.img"></div>
  </div>
</div>
