<div
  [ngStyle]="{display: showSheet?'block':'none'}"
  class="bottom-sheet-container"
  (click)="onBackdropClick($event)"
  data-sheetcontainer="true"
>
  <div
    class="content"
    [ngStyle]="{bottom: bottomPosition}"
  >
    <ng-content></ng-content>
  </div>
</div>
