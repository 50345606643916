import { Pipe, PipeTransform } from '@angular/core';
import { mapDataToSkuCard } from '@laasy/common-layout';

@Pipe({
  name: 'mapCollectionSectionSkus',
  standalone: true
})
export class MapCollectionSectionSkusPipe implements PipeTransform {

  transform(skus: {rank: number, sku: any}[], ...args: unknown[]): any[] {
    return skus?.map(item => mapDataToSkuCard(item.sku));
  }

}
