import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { appInitFailed } from '@laasy/common-layout'
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

sessionStorage.setItem('loaderRequestCount', '0')
sessionStorage.setItem("browserMonitorID",environment.browserMonitorID)

platformBrowserDynamic().bootstrapModule(AppModule)
.catch(err => {
  appInitFailed(err);
});
