import { Injectable } from '@angular/core';
import { AuthorizedApiService } from '@laasy/common-layout';
import { filter } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { dropShipOrder } from '../../utilities/apiConstants';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DropShipOrderService {

  constructor(private readonly authService: AuthorizedApiService) { }

  async placeOrder(order:string):Promise<void>{
    const order_json = JSON.parse(order)
    const res = await this.authService.post(dropShipOrder,order_json,).toPromise()
  }

  async Order(data:string):Promise<void>{
    const order = this.decodeMessage(data)
    await this.placeOrder(order)
    // trigger for thankyou modal on parent tab
    // localStorage.setItem('dropShipOrder',(Date.now() +''))
  }


  decodeMessage(msg: string) :string{
    return decodeURIComponent(escape(atob(msg)));
  }

  // open thankyou modal in parent tab
  openThankyouModal():Observable<any>{
    return new Observable((observer) => {
      const storageListener = (e:any) => {
        observer.next(e);
      };
      window.addEventListener('storage', storageListener);
      // Clean up the event listener when unsubscribed
      return () => {
        window.removeEventListener('storage', storageListener);
      };
    }).pipe(
      filter((event:any) => event?.key === 'dropShipOrder' && event?.newValue)
    );

  }
}
