<div class="search-result-sortby search-section-menu" *ngIf="sortByitems.length">
  <div class="sort-by-text-heading">Sort by:</div>
  <p-menu #menu [model]="sortByitems" [popup]="true">
    <ng-template pTemplate="item" let-item>
      <div class="select-sort-by">
        <div class="select-sort-by-text" [ngClass]="{'selected-sort-by':item.isSelected}">{{item.label}}</div>
        <div *ngIf="item.isSelected">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path
              d="M7.95703 15.0003L3.20703 10.2503L4.39453 9.06283L7.95703 12.6253L15.6029 4.97949L16.7904 6.16699L7.95703 15.0003Z"
              fill="black" />
          </svg>
        </div>
      </div>
    </ng-template>
  </p-menu>
  <div class="text" (click)="menu.toggle($event)">{{currentSortBy}}</div>
  <div class="dropicon" (click)="menu.toggle($event)">
    <img src="../../../../assets/icons/downarrow.svg" alt="">
  </div>
</div>
