export const commonEnvConfig = {
  isHome: false,
  productName:'Shop',
  internalRoutes: [{ title: 'Shop', url: '' }],
  logglyKey :'8c22b8d0-1ce7-4536-bd0a-d4ad888e8d27',
  whoAmI: "",
  cognitoUserPoolId: '',
  cognitoAppClientId: '',
  aws_cognito_social_providers:[],
  aws_domain:"",
  aws_redirectSignIn:"",
  aws_redirectSignOut:"",
  redirectHomeBase:"",
  refreshTokenInterval:60, //minutes

}
