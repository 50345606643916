import { Injectable } from "@angular/core";
import { BehaviorSubject, filter, Subject } from "rxjs";
import { FilterChip } from "../../models/Search";


@Injectable({
  providedIn: 'root'
})
export class SearchSelectedFilterService {

  filterChips$ = new BehaviorSubject<FilterChip[]>([]);
  filterChipList: FilterChip[] = [];
  showClear$ = new BehaviorSubject<boolean>(false);

  addSelectedFiltersData(label: string, filterName: string, isCategory?: boolean | false): void{
     let isSelected =  this.filterChipList.some(res => res.label === label)
    if(!isSelected) {
      if(isCategory){
        this.updateSelectedFiltersData([]);
      } else{
        this.filterChipList.push({label: label, filterName: filterName});
      }
    }
    this.filterChips$.next(this.filterChipList);
  }

  updateSelectedFiltersData(filterList: any): void{
    this.filterChipList = filterList;
    this.filterChips$.next(this.filterChipList)
  }

  showClearButton(show: boolean): void {
    this.showClear$.next(show);
  }

}
