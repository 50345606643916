<app-static-banner-card (touchstart)="swipe($event, 'start')" (touchend)="swipe($event, 'end')" [bgColor]="bannerDetails[bannerIndex].color">
  <div leftSection><ng-content select="[leftSection]"></ng-content></div>
  <div rightSection style="height: 100%;"><ng-content select="[rightSection]"></ng-content></div>
</app-static-banner-card>
<div class="main-container-banner-dot" *ngIf="bannerDetails.length >1">
  <div class="carousel-dots-container">
    <div class="carousel-dot" *ngFor="let x of bannerDetails; index as i" (click)="updateBannerIndx(i)"
      [ngClass]="{'active': isActiveImg(i)}"></div>
  </div>
</div>
