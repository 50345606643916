import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ShopService } from '@laasy/common-layout/shop';
import { AuthenticationService } from 'src/app/shared/services/authentication/authentication.service';
import { redirectToHome } from 'src/app/shared/utilities/helpers';

@Injectable()
export class AuthGuard  {

  constructor(private shopService: ShopService,private readonly authenticationService: AuthenticationService,private readonly router: Router) { }

  async canActivate(): Promise<boolean> {
    return  await this.isAuthenticated()
  }

  async canActivateChild(): Promise<boolean> {
    return  await this.isAuthenticated()
  }

  private async isAuthenticated(){
    const currentSession = await this.authenticationService.GetCurrentSession();

    if(currentSession != null)
    {
      this.shopService.getSavedSkuIDList()
      return true;
    }

    redirectToHome()
    // required to prevent redirect to shop landing page
    return true;
  }
}
