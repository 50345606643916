import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError as observableThrowError } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { LoaderService, urlProtocol,HIDE_LOADER } from '@laasy/common-layout';
import { environment } from 'src/environments/environment';
import { redirectToHome } from 'src/app/shared/utilities/helpers';

@Injectable({
  providedIn: 'root'
})
export class HttpExchangeInterceptor implements HttpInterceptor {
  uploadUrls = [];
  requests = 0;

  constructor(private readonly loaderService: LoaderService,) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // hide loader in landing page
    // TODO: update request context of each http API call made in homepage to hide loader
    const isHomePage = window.location.pathname == '/'
    if(!request?.context?.has(HIDE_LOADER) && !isHomePage){
      this.requests += 1;
      this.loaderService.show();
    }

    return next.handle(request).pipe(tap({
      next: (event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // do stuff with response if you want
        }
      },
      error: (res: any) => this.handleHttpError(res)
    })).pipe(finalize(() => {
      this.requests -= 1;
      this.loaderService.hide();
    }));
  }

  handleHttpError(res: any): any {
    if (res instanceof HttpErrorResponse) {
      if ((res.status === 401 || res.status === 403)) redirectToHome()
      return of(res);
    }
    return null;
  }

}
