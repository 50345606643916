import { Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { PageNotFoundComponent, ErrorPageComponent } from '@laasy/common-layout/common-components';
import { LandingComponent } from './pages/landing/landing.component';
import { SearchResultComponent } from './pages/search-result/search-result.component';
import { CollectionResultComponent } from './pages/collection-result/collection-result.component';
import { PostPurchaseComponent } from './pages/post-purchase/post-purchase.component';

export const ROUTES: Routes = [
  { path: '', component: LandingComponent, canActivate: [AuthGuard] },
  { path: 'search-result', component: SearchResultComponent, canActivate: [AuthGuard] },
  { path: 'collections/:collectionType', component: CollectionResultComponent, canActivate: [AuthGuard] },
  { path: 'support/error', component: ErrorPageComponent },
  // this is dummy page (not visible to user) used by home app to stay in shop on clear cache
  { path: 'clear-cache', component: PageNotFoundComponent },
  { path: 'post-purchase', component: PostPurchaseComponent, canActivate: [AuthGuard] },
  { path: '**', component: PageNotFoundComponent, data: { showFAQ: false }, canActivate: [AuthGuard] },
];

