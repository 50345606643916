<app-search-section></app-search-section>
<app-breadcrumb [page]="featured_label" *ngIf="isFeatured"></app-breadcrumb>
<p-progressBar
  *ngIf="!(swfService.responseData.received || swfService.responseData.error)"
  mode="indeterminate"
  styleClass="container-laasy mb-4"
  [style]="{'height': '5px'}">
</p-progressBar>
<div *ngIf="swfService.responseData.received" class="search-result-parent-container l-container">
  <div class="md filters-section-container">
    <ng-template [ngTemplateOutlet]="filterSection"></ng-template>
  </div>
  <app-empty-state-display
    style="flex-grow: 2;"
    [searchQuery]="searchQuery"
    *ngIf="swfService.responseData.received && results.length === 0"
  >
  </app-empty-state-display>
  <div
    *ngIf="swfService.responseData.received && results.length !== 0"
    class="result-section">
    <div class="sm">
      <ng-template [ngTemplateOutlet]="(isFeatured)?resultFeaturedHeader:resultCount"></ng-template>
    </div>
    <div class="result-header">
      <div class="search-result-left-container">
        <div class="md">
          <ng-template [ngTemplateOutlet]="(isFeatured)?resultFeaturedHeader:resultCount"></ng-template>
        </div >
        <div class="sm filterlabel-chip" (click)="openFilterSection()">Filters</div>
      </div>
      <app-sort-by
        #sortBy [items]="sortByitems"
        (selected)="updateSortBy($event)"
        (selectedString)="setSortValue($event)">
      </app-sort-by>
    </div>
    <div class="results-container">
      <app-shop-product-card [items]="results" [isFlexwrap]="true" [isDataLoaded]="skeletonLoader_isDataLoaded"></app-shop-product-card>
    </div>
  </div>
</div>

<div class="search-result-parent-container l-container">
  <div class="dummy-filter-pagination"></div>
  <div class="pagination" [ngClass]="{'node-visible':showPagination,'node-hidden':!showPagination}">
    <app-pagination [currentPageNumber]="currentPageNumber" [totalPages]="totalPages"
        (onPageChangeEvent)="getDataByPage($event)"></app-pagination>
  </div>
</div>

<ng-template #filterSection>
  <app-filters></app-filters>
</ng-template>

<ng-template #resultCount>
  <b class="result-label">Results</b>
  <div class="result-count">Showing {{totalItm}} <span *ngIf="searchQuery.trim();">for <b [title]="searchQuery">“{{searchQuery | truncate}}”</b></span><span *ngIf="!searchQuery.trim()">results</span></div>
</ng-template>

<ng-template #resultFeaturedHeader>
  <div class="featured-search-result-label">
    <img *ngIf="featured_logo" [src]="featured_logo" class="featured-search-result-image">
    <b class="result-label">
      <span *ngIf="featured_logo">Shop</span>
      {{featured_label}}
    </b>
  </div>
</ng-template>

<app-bottom-sheet #bSheet>
  <div class="search-result-parent-container p-1-25rem">
    <ng-template [ngTemplateOutlet]="filterSection"></ng-template>
  </div>
</app-bottom-sheet>
