import { ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchSectionComponent } from 'src/app/shared/components/search-section/search-section.component';
import { mapDataToSkuCard } from '@laasy/common-layout';
import { CheckboxModule } from 'primeng/checkbox';
import { FormsModule } from '@angular/forms';
import { SearchService } from 'src/app/shared/services/search/search.service';
import { SearchConst, searchDefaultSort } from 'src/app/shared/utilities/constants';
import { SearchResponse } from 'src/app/shared/models/Search';
import { BottomSheetComponent } from 'src/app/shared/components/bottom-sheet/bottom-sheet.component';
import { SortByComponent } from 'src/app/shared/components/sort-by/sort-by.component';
import { RadioButtonModule } from 'primeng/radiobutton';
import { MenuModule } from 'primeng/menu';
import { SearchWithFilterService } from 'src/app/shared/services/search/search-with-filter.service';
import { Subject, Subscription, debounceTime } from 'rxjs';
import { SliderModule } from 'primeng/slider';
import { getPriceRange } from 'src/app/shared/utilities/helpers';
import { ActivatedRoute, Router } from '@angular/router';
import { LandingService } from 'src/app/shared/services/Page/landing.service';
import { BreadcrumbComponent } from 'src/app/shared/components/breadcrumb/breadcrumb.component';
import { SortBy } from 'src/app/shared/models/sortBy';
import { environment } from 'src/environments/environment';
import { SearchSectionService } from 'src/app/shared/services/search/search-section.service';
import { EmptyStateDisplayComponent } from 'src/app/shared/components/empty-state-display/empty-state-display.component';
import { TruncatePipe } from 'src/app/shared/pipes/truncate.pipe';
import { ProgressBarModule } from 'primeng/progressbar';
import { ShopModule } from '@laasy/common-layout/shop';
import { CommonComponentModule } from '@laasy/common-layout/common-components';
import { FiltersComponent } from "../../shared/components/filters/filters.component";
import { SearchSelectedFilterService } from 'src/app/shared/services/search/search-selected-filter.service';

@Component({
  selector: 'app-search-result',
  standalone: true,
  imports: [
    CommonModule,
    SearchSectionComponent,
    CheckboxModule,
    FormsModule,
    BottomSheetComponent,
    SortByComponent,
    MenuModule,
    RadioButtonModule,
    SliderModule,
    BreadcrumbComponent,
    EmptyStateDisplayComponent,
    TruncatePipe,
    ProgressBarModule,
    ShopModule,
    CommonComponentModule,
    FiltersComponent
],
  templateUrl: './search-result.component.html',
  styleUrls: ['./search-result.component.scss'],
})
export class SearchResultComponent implements OnInit, OnDestroy {
  @ViewChild('bSheet') bSheet !: BottomSheetComponent;
  @ViewChild('sortBy') sortBy !: SortByComponent;

  searchQuery = ''
  sortByitems: SortBy[] = SearchConst.CategoryItemDict
  results: any[] = []
  searchResponse!: SearchResponse
  totalItm = this.results.length
  currentPageNumber = 1
  totalPages!: number;
  pageSize: number = 20
  subsList !: Subscription[];
  priceRange: number[] = [];
  minPrice = 0;
  maxPrice !: number;
  showClear = false;
  filterChips: {
    label: string,
    filterName: string
  }[] = [];
  dom: any = {filter: null, header: null};

  featured_logo=''
  featured_label= ''
  featured_type=''
  isFeatured = false
  apiConfig ={baseApiUrl:environment.apiUri, tenantID:environment.whoAmI, merchantLogoUrl: environment.s3MerchantLogoUrl}
  turningFeaturedOff = false;
  sortValue = searchDefaultSort;
  showPagination = false
  skeletonLoader_isDataLoaded = false
  searchResults:any;

  constructor(
    private searchService: SearchService,
    public readonly swfService: SearchWithFilterService,
    private readonly cdRef: ChangeDetectorRef,
    private route: ActivatedRoute,
    private landingService: LandingService,
    private router: Router,
    public searchSecService: SearchSectionService,
    private ssfService: SearchSelectedFilterService
  ) { }

  ngOnInit(): void {

    this.subsList = [
      this.searchService.onSearch.subscribe({
        next: (query) => this.router.navigate(['search-result'],{
          queryParams: {
            ...this.route.snapshot.queryParams,
            query
          }
        })
      }),
      this.route.queryParams.subscribe({
        next: (qParams) => this.onParamsChange(qParams['query'])
      }),
      this.swfService.onResult.subscribe({
        next: (res) =>{
          this.searchResults = res;
          this.skeletonLoader_isDataLoaded = true
          this.setResult(res)
          scrollTo(0,0)
          this.stylePagination()
        }
      }),
    ]
  }

  stylePagination(){
    const dummyFilterdiv = document.querySelector('.dummy-filter-pagination') as HTMLElement
    const filterSection = document.querySelector('.filters-section') as HTMLElement
    dummyFilterdiv!.style!.width = filterSection?.getBoundingClientRect()?.width+'px'
    this.showPagination =true
  }

  onParamsChange(query: string){
    if(this.turningFeaturedOff){
      this.turningFeaturedOff = false;
      return;
    }
    this.searchService.setQuery(query);
    this.searchQuery = query;
    this.filterChips = [];
    this.resetSortBy();
    this.swfService.resetSorting();
    this.priceRange = [];
    this.swfService.resetResponseData();
    this.isFeatured = this.route.snapshot.queryParams['isFeatured'] ===  "true";
    if(this.isFeatured){
      this.executeFeaturedMode()
    }else{
      this.executeNormalMode();
    }
    this.resetPageAndSearch();
    this.cdRef.detectChanges();
  }

  executeNormalMode(){
    this.showClear = false;
    this.swfService.resetRequestData();
  }

  executeFeaturedMode(){
    this.swfService.resetFilter();
    const featuredObj = this.initFeatured()
    this.swfService.featuredSearch(featuredObj)
  }

  ngOnDestroy(): void {
    this.subsList.forEach(sub => sub?.unsubscribe());
    this.searchService.setQuery('');
    this.swfService.resetResponseData();
  }

  initFeatured(){
    if(this.route.snapshot.queryParams['isFeatured'] == "true") this.isFeatured = true
    else return
    const brand = this.route.snapshot.queryParams[SearchConst.type.Brands]
    const merchant = this.route.snapshot.queryParams[SearchConst.type.Merchants]
    const categories = this.route.snapshot.queryParams[SearchConst.type.Categories];
    let result = {}

    if(brand){
      this.featured_type = SearchConst.type.Brands
      result = {...result,Brands:[brand]}
      this.landingService.getTopBrands().subscribe((data)=>this.updateFeaturedData(data,brand))
    }
    if(merchant){
      this.featured_type = SearchConst.type.Merchants
      result = {...result,Merchants:[merchant]}
      this.landingService.getTopMerchants().subscribe((data)=>this.updateFeaturedData(data,merchant))
    }
    if(categories){
      this.featured_type = SearchConst.type.Categories
      result = {...result,Categories:[categories]}
      const data = [{
        label: categories,
        image: null
      }];
      this.updateFeaturedData(data,categories)
    }
    return result
  }

  updateFeaturedData(data:any, name:string){
    const featured_ = data.find((obj:any) => obj.label === name)
    if(!featured_) this.router.navigateByUrl('/')
    this.featured_logo=featured_.image
    this.featured_label =featured_.label
    this.ssfService.addSelectedFiltersData(this.featured_label, this.featured_type, true)
  }

  updateSortBy(val: any) {
    this.swfService.requestData.sortBy = val;
    this.sortValue.value = val;
    this.resetPageAndSearch()
  }

  resetSortBy() {
    this.sortValue = {...searchDefaultSort}
    this.sortBy?.setSortBy(searchDefaultSort.label, searchDefaultSort.value);
  }

  setSortValue(label: string){
    this.sortValue.label = label;
  }


  getDataByPage( pageNumber: number) {
    this.swfService.requestData.pageNumber = pageNumber
    this.currentPageNumber = pageNumber;
    this.swfService.search(this.searchQuery)
    this.showPagination =false
  }

  resetPageAndSearch(){
    this.getDataByPage(1);
  }

  openFilterSection() {
    this.bSheet.open();
  }

  setResult(response: SearchResponse) {
    this.transform(response.data)
    this.totalItm = response.totalCount
    this.totalPages = Math.ceil(this.totalItm/this.pageSize);
    this.cdRef.detectChanges();
    const { label, value } = this.sortValue;
    this.sortBy?.setSortBy(label,value)
  }


  transform(data: any) {
    this.results = data.map( (item: any) =>mapDataToSkuCard(item))
  }

  @HostListener('window:scroll',[])
  fixFilterSection(){
    const filter = document.querySelector('.filters-section') as HTMLElement;
    const header = document.querySelector('.l-header-container');
    if(!(filter && header)) return;
    const filterRect = filter.getBoundingClientRect();
    const headerRect = header.getBoundingClientRect();
    let top = Math.floor(filterRect.top);
    let height = Math.ceil(headerRect.height);

    if(top < (height+5)){
      filter.style.top = `${height+5}px`;
      filter.style.position = 'sticky';
    }
  }

  turnOffIsFeatured(){
    if(this.isFeatured){
      this.isFeatured = false
      this.turningFeaturedOff = true;
      this.router.navigate(['search-result'],{
        queryParams: {query: this.searchQuery}
      })
    }else{
      this.turningFeaturedOff = false;
    }
  }
}
