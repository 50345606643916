import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { DeviceTypeDirective } from '@laasy/common-layout';

@Component({
  selector: 'app-breadcrumb',
  standalone: true,
  imports: [CommonModule,DeviceTypeDirective],
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent {
  @Input() page = ''

  constructor(private router: Router){}

  home(){
    this.router.navigateByUrl('/')
  }
}
