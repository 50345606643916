import { AfterViewInit, Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appShopByCategory]',
  standalone: true
})
export class ShopByCategoryDirective implements AfterViewInit {

  el: ElementRef;
  isMobileView: boolean = false;

  constructor(el: ElementRef) {
    this.el = el;
  }

  ngAfterViewInit(): void {
    this.updateCards(this.el);
    this.onWindowResize('');
  }

  @HostListener('window:resize', ['$event'])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onWindowResize(event: any): void {
    if (window.innerWidth < 961) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
  }

  updateCards(el: ElementRef) {
    const dimensionImg = (this.isMobileView) ? '2.5625rem' : '3.625rem';
    const dimensionImgContainer = (this.isMobileView) ? '4.04025rem' : '5.25rem';

    const imgContainer = el.nativeElement.querySelectorAll('.l-circular-card-image-container');
    imgContainer.forEach((element: HTMLElement) =>{
      element.style.backgroundColor = 'var(--l-primary-tenant-stats-color)';
      element.style.width = dimensionImgContainer;
      element.style.height = dimensionImgContainer;
      element.style.borderRadius = dimensionImgContainer;
    });
    const image = el.nativeElement.querySelectorAll('.l-circular-card-image');

    image.forEach((element: HTMLElement) => {
      element.style.width = dimensionImg;
      element.style.height = dimensionImg;
      element.style.borderRadius = dimensionImg;
    });
  }
}


