import { AfterViewInit, Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appStaticBannerCard]',
  standalone: true
})
export class StaticBannerCardDirective implements AfterViewInit {

  el: ElementRef

  constructor(el: ElementRef) {
    this.el = el
  }

  ngAfterViewInit(): void {
    this.onWindowResize('')
  }

  @HostListener('window:resize', ['$event'])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onWindowResize(event: any): void {
    if (window.innerWidth < 450) {
      this.updateCards('4.9375rem');
    }
    this.updateImgContainer(this.getContainer())

  }

  getContainer() {
    return this.el.nativeElement.querySelector('.main-container-banner')
  }



  updateCards(containerHeight: string) {
    const container = this.getContainer()
    container.style.height = containerHeight
  }

  updateImgContainer(container: any) {
    container.style.justifyContent = 'space-between'
    const rightContainer = container.querySelector('.right-container')
    rightContainer.style.position = 'unset'
  }
}


