<div class="main-container-banner" [ngStyle]="{'background':bgColor}">
  <div class="heading-text">
    <div class="heading-text-content">
      <ng-content select="[leftSection]"></ng-content>
    </div>
  </div>

  <div class="right-container">
    <ng-content select="[rightSection]"></ng-content>
  </div>
</div>
