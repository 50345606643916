import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchSectionComponent } from 'src/app/shared/components/search-section/search-section.component';
import { DeviceTypeDirective } from '@laasy/common-layout';
import { ActivatedRoute, Router } from '@angular/router';
import { CollectionResultService } from 'src/app/shared/services/Page/collection-result.service';
import { SortByComponent } from 'src/app/shared/components/sort-by/sort-by.component';
import { SearchConst } from 'src/app/shared/utilities/constants';
import { StaticBannerCardComponent } from 'src/app/shared/components/static-banner-card/static-banner-card.component';
import { StaticBannerCardDirective } from 'src/app/pages/collection-result/static-banner-card.directive';
import { ProductSKUItem } from 'src/app/shared/models/Search';
import { sortItems } from 'src/app/shared/utilities/helpers';
import { BreadcrumbComponent } from 'src/app/shared/components/breadcrumb/breadcrumb.component';
import { SortBy } from 'src/app/shared/models/sortBy';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';
import { SearchService } from 'src/app/shared/services/search/search.service';
import { SearchSectionService } from 'src/app/shared/services/search/search-section.service';
import { ShopModule } from '@laasy/common-layout/shop';
@Component({
  selector: 'app-collection-result',
  standalone: true,
  imports: [CommonModule, SearchSectionComponent, SortByComponent, DeviceTypeDirective, StaticBannerCardComponent, StaticBannerCardDirective, BreadcrumbComponent,ShopModule],
  templateUrl: './collection-result.component.html',
  styleUrls: ['./collection-result.component.scss']
})
export class CollectionResultComponent implements OnInit, AfterViewInit, OnDestroy {

  page:string = ''
  resultHeader:string = ''
  results: ProductSKUItem[] = []
  sortByitems:SortBy[] = SearchConst.CategoryItemDict
  currentSortBy:string = ''
  banner = { img: '', heading1: '', heading2: '', color: '', imgLeft: '' }
  apiConfig ={baseApiUrl:environment.apiUri, tenantID:environment.whoAmI, merchantLogoUrl: environment.s3MerchantLogoUrl}
  @ViewChild('sortBy') childComponent: SortByComponent | undefined;
  defaultSort !:SortBy | undefined
  subs !: Subscription[];
  skeletonLoader_isDataLoaded = false

  constructor(
    private crService: CollectionResultService,
    private route: ActivatedRoute,
    private router: Router,
    private searchService: SearchService,
    public searchSectionService: SearchSectionService
  ) { }

  ngOnInit(): void {
    this.subs = [
      this.route.params.subscribe(params => {
        this.page = params['collectionType']
        this.getResults(params['collectionType'])
      }),
      this.searchService.onSearch.subscribe({
        next: (query) => this.router.navigate(['search-result'],{
          queryParams: {query}
        })
      })
    ]
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub?.unsubscribe());
  }

  ngAfterViewInit(): void {
    this.initSorting(null)
  }

  getResults(collectionType: string) {
    this.crService.getCollectionData(collectionType).subscribe(data => {
      this.defaultSort = undefined
      this.results = data.skus
      this.initSorting(data.defaultSort)
      this.updatePage(data)
      this.skeletonLoader_isDataLoaded = true
    })
  }

  initSorting(id: number|null){
    if(id != null) this.defaultSort = SearchConst.CategoryItemDict.find((x: any) => x.id === id )
    if(this.childComponent && this.defaultSort) this.childComponent.selectSortBy(this.defaultSort?.sortKey,this.defaultSort?.id)
    this.updateSortBy(this.defaultSort?.sortKey)
  }

  updatePage(config:any) {
      this.banner = { img: config.imageRight, heading1: config.bannerTitle, heading2: config.bannerHeading2, color: config.bannerBgColor, imgLeft: config.imageLeft }
      this.resultHeader = config.resultHeader || 'Trending items'
  }

  updateSortBy(val: any) {
    this.currentSortBy = val
    const itmSort: any = SearchConst.CategoryItemDict.filter((x: any) => x.sortKey === val)[0]
    this.results = sortItems(this.results, itmSort?.itemKey, itmSort?.reverse)
  }

  //if need to use div background
  getStyle(img:string, direction?:string) {
    let bgPos = "100% 50%"
    if(direction==='left') bgPos = "0% 50%"
    return { 'background': "url('" + img + "') "+bgPos, }
  }
}
