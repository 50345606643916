<div class="post-purchase-screen" >
  <div class="text-center" *ngIf="isSuccess;else error">
    <svg class="mt-4-percent" width="170" height="170" viewBox="0 0 170 170" fill="none" xmlns="http://www.w3.org/2000/svg">
      <use href="../../../assets/icons/shoppingCartIcon.svg#packageIcon" />
    </svg>
    <div class="heading max-width">{{message.heading}}</div>
    <div class="description max-width">{{message.description}}</div>
    <button class="primary-button continue" (click)="openPurchaseHistory()">See my purchase</button>
  </div>
  <ng-template #error>
    <svg class="mt-4-percent" width="170" height="170" viewBox="0 0 170 170" fill="none" xmlns="http://www.w3.org/2000/svg">
      <use href="../../../assets/icons/DropShipAttributionError.svg#packageIcon"/>
    </svg>
    <div class="heading max-width">{{message.heading}}</div>
    <div class="description max-width">{{message.description}}</div>
    <div *ngIf="orderNo" class="orderNo">Order Number: <span>{{orderNo}}</span></div>
    <button class="primary-button continue" (click)="openSupport()">Contact support</button>
  </ng-template>

</div>

