import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mapCardCollection',
  standalone: true
})
export class MapCardCollectionPipe implements PipeTransform {

  transform(cardList: any[], ...args: unknown[]): unknown {
    return cardList.map(card => ({
      heading: card.bannerTitle,
      link: '/collections/'+ card.collectionName,
      img: card.imageRight,
      color: card.bannerBgColor,
      ctaText: card.cardCtaText || 'Shop now'
    }))
  }

}
