import { Component, HostListener, Input,  OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BannerDetails } from 'src/app/shared/utilities/constants';
import { BaseSwipeBannerComponent } from 'src/app/shared/components/base-swipe-banner/base-swipe-banner.component';
import { Banner } from 'src/app/shared/models/landing';

@Component({
  selector: 'app-banner-card',
  standalone: true,
  imports: [CommonModule, BaseSwipeBannerComponent],
  templateUrl: './banner-card.component.html',
  styleUrls: ['./banner-card.component.scss']
})
export class BannerCardComponent implements OnInit {
  @Input() bannerDetails!:Banner[]
  updateBanner = 1
  bannerIndex = 0
  isMobile = false
  bannerRotateInterval;

  constructor() {
    this.bannerRotateInterval = setInterval(() => this.updateBanner+=1, 5000)
  }

  ngOnInit(): void {
    this.onWindowResize()
  }
  @HostListener('window:resize', ['$event'])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onWindowResize(): void {
    this.isMobile = window.innerWidth < 960;
  }

  updateBannerIndex(i: any) {
    this.bannerIndex = i
    clearInterval(this.bannerRotateInterval);
    this.bannerRotateInterval = setInterval(() => this.updateBanner+=1, 5000)
  }

}
