
<app-base-swipe-banner [bannerDetails]="bannerDetails" (updateBannerIndex)="updateBannerIndex($event)" [updateBanner]="updateBanner" *ngIf="bannerDetails">
  <div leftSection>
    <div>
      {{this.bannerDetails[this.bannerIndex].prefix_text_underlined}}
      <div id="text-underline" *ngIf="this.bannerDetails[this.bannerIndex].isTextUnderlined">
        {{this.bannerDetails[this.bannerIndex].text_underlined}}
        <div class="underlined-img">
          <ng-container [ngTemplateOutlet]="underline" [ngTemplateOutletContext]="{width:100}" *ngIf="isMobile"></ng-container>
          <ng-container [ngTemplateOutlet]="underline" [ngTemplateOutletContext]="{width:232}" *ngIf="!isMobile"></ng-container>
        </div>
      </div>
    </div>
    <div>
      {{this.bannerDetails[this.bannerIndex].post_text_underlined}}
    </div>
  </div>

  <div rightSection>
    <div class="ellipse" *ngIf="this.bannerDetails[this.bannerIndex].isEllipse"></div>
    <div class="showcase-cards" [ngClass]="{'right-0 bottom-0':!this.bannerDetails[this.bannerIndex].isSparkle}">
      <img [src]="this.bannerDetails[this.bannerIndex].image_url" height="80" *ngIf="isMobile" alt="" [ngClass]="{'banner-height':!this.bannerDetails[this.bannerIndex].isSparkle}">
      <img [src]="this.bannerDetails[this.bannerIndex].image_url" height="100%" *ngIf="!isMobile" alt="" [ngClass]="{'banner-height':!this.bannerDetails[this.bannerIndex].isSparkle}">
    </div>
    <div class="sparkel" *ngIf="this.bannerDetails[this.bannerIndex].isSparkle">
      <img src="../../../../assets/SparklesImage.png" alt="">
    </div>
  </div>
</app-base-swipe-banner>

<ng-template #underline let-width="width">
  <svg xmlns="http://www.w3.org/2000/svg" [attr.width]="width" height="9" viewBox="0 0 232 9" fill="none">
    <path d="M2 7C84.992 0.547671 160.688 0.887744 230 4.91384" stroke="var(--l-primary-tenant-color)"
      stroke-width="3" stroke-linecap="round" />
  </svg>
</ng-template>

