import { Component, OnInit } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { environment } from 'src/environments/environment';
import { TenantService } from '../../../shared/services/tenant/tenant.service';
import { CommonLayoutModule } from '@laasy/common-layout';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss'],
    standalone: true,
    imports: [CommonLayoutModule, RouterOutlet,CommonModule]
})
export class LayoutComponent implements OnInit {
  hideHeaderFooter = true

  async ngOnInit(): Promise<void> {
    if(!window.location.href.includes('clear-cache')){
      this.hideHeaderFooter = false
    }
  }


  onActivate(): void {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }
}
