import { Injectable } from '@angular/core';
import { SearchRequest, SearchResponse } from '../../models/Search';
import { BehaviorSubject, retry } from 'rxjs';
import { searchApi } from '../../utilities/apiConstants';
import { AuthorizedApiService } from '@laasy/common-layout';
import { environment } from 'src/environments/environment';
import { NotificationService } from '../common/notification.service';
import { searchDefaultSort } from '../../utilities/constants';

@Injectable({
  providedIn: 'root'
})
export class SearchWithFilterService {

  requestData !: SearchRequest;
  responseData: SearchResponse;
  onResult: BehaviorSubject<SearchResponse>;

  constructor(
    private readonly authService:AuthorizedApiService,
    private readonly notification: NotificationService
  ) {
    this.resetRequestData();
    this.responseData = {
      aggregations: {
        filters: {}
      },
      data: [],
      totalCount: 0,
      received: false,
      error: false
    }
    this.onResult = new BehaviorSubject(this.responseData);
  }

  featuredSearch(featuredData:any){
    const keys = Object.keys(featuredData)
    keys.forEach((key:string) => {
      if(featuredData[key])
        this.requestData.filter[key] = featuredData[key]
    });
  }

  search(query: string){
    this.requestData.searchKeyword = query;
    this.requestData.contextText = query;
    this.responseData.received = false;
    this.responseData.error = false;

    this.authService.post<SearchResponse>(searchApi,this.requestData, ).pipe(retry(2)).subscribe({
      next: (res: SearchResponse) => {
        this.responseData = res
        this.responseData.received = true;
        this.onResult.next(this.responseData);
      },
      error: () => {
        this.responseData.error = true;
        this.showErrorToast()
      }
    })
  }

  resetPageNumber(){
    this.requestData.pageNumber = 1;
  }

  resetSorting(){
    this.requestData.sortBy = searchDefaultSort.value;
  }

  resetRequestData(){
    this.requestData = {
      contextText: "",
      filter: {
          Brands: [],
          cashbackRange: {
              highest: 0,
              lowest: 0
          },
          Categories: [],
          Colors: [],
          priceRange: {
              highest: 0,
              lowest: 0
          },
          Sizes: []
      },
      pageNumber: 1,
      pageSize: 20,
      searchKeyword: "",
      sortBy: searchDefaultSort.value,
      typeOfSearch: this.requestData?.typeOfSearch || ''
    }
  }

  setPriceRange(lowest: number, highest: number){
    this.requestData.filter.priceRange = {highest,lowest};
  }

  resetFilter(){
    this.requestData.filter = {
      Brands: [],
      cashbackRange: {
          highest: 0,
          lowest: 0
      },
      Categories: [],
      Colors: [],
      priceRange: {
          highest: 0,
          lowest: 0
      },
      Sizes: []
    }
  }

  resetResponseData(){
    this.responseData = {
      aggregations: {
        filters: {}
      },
      data: [],
      totalCount: 0,
      received: false,
      error: false
    };
    this.onResult.next(this.responseData);
  }

  showErrorToast(){
    const title = 'Something went wrong';
    const msg = 'Please try again or contact support'
    this.notification.showError(title,msg);
  }

}
