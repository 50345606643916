import { Component, EventEmitter, HostListener, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StaticBannerCardComponent } from '../static-banner-card/static-banner-card.component';

@Component({
  selector: 'app-base-swipe-banner',
  standalone: true,
  imports: [CommonModule,StaticBannerCardComponent],
  templateUrl: './base-swipe-banner.component.html',
  styleUrls: ['./base-swipe-banner.component.scss']
})
export class BaseSwipeBannerComponent implements OnChanges {

  @Input() bannerDetails: any[] = []
  @Input() updateBanner: any
  @Output() updateBannerIndex = new EventEmitter<any>();
  bannerIndex = 0
  isBannerUpdated = false
  activeBanner = this.bannerDetails[0]

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['updateBanner'] && changes['updateBanner'].currentValue) {
      this.updateBannerImg()
    }
  }
  updateBannerImg() {
    if (this.isBannerUpdated) {
      this.isBannerUpdated = false
      return
    }
    const arrayLength = this.bannerDetails.length
    if (!arrayLength) return
    this.bannerIndex = (this.bannerIndex + 1) % arrayLength
    this.updateBannerIndex.emit(this.bannerIndex)
  }

  updateBannerIndx(i: number) {
    this.bannerIndex = i
    this.isBannerUpdated = true
    this.updateBannerIndex.emit(this.bannerIndex)
  }

  isActiveImg(i: number) {
    return i == this.bannerIndex
  }

  private swipeCoord?: [number, number];
  private swipeTime?: number;

  swipe(e: TouchEvent, when: string): void {
    const coord: [number, number] = [e.changedTouches[0].clientX, e.changedTouches[0].clientY];
    const time = new Date().getTime();

    if (when === 'start') {
      this.swipeCoord = coord;
      this.swipeTime = time;
    } else if (when === 'end') {
      if (this.swipeCoord && this.swipeTime) {
        const direction = [coord[0] - this.swipeCoord[0], coord[1] - this.swipeCoord[1]];
        const duration = time - this.swipeTime;

        if (duration < 1000 && Math.abs(direction[0]) > 30 && Math.abs(direction[0]) > Math.abs(direction[1] * 3)) {
          const swipe = direction[0] < 0 ? 'next' : 'previous';
          // Do whatever you want with swipe
          console.log(swipe)
          if (swipe === 'previous') {
            this.isBannerUpdated = true
            if (this.bannerIndex == 0) this.bannerIndex = this.bannerDetails.length - 1
            else this.bannerIndex -= 1
          }
          else this.updateBannerImg()
          this.updateBannerIndex.emit(this.bannerIndex)

        }
      }

    }
  }
}
