import { urlProtocol } from "@laasy/common-layout";
import { environment } from "src/environments/environment";

export const getPriceRange = (priceList: {
  count: number,
  item: string
}[]) => {
  let min = Infinity, max = -1,value;
  for(let price of priceList){
    value = parseFloat(price.item);
    min = min > value? value : min;
    max = max < value ? value : max;
  }
  min = Math.floor(min);
  max = Math.ceil(max);

  return [min,max];
}

export function sortItems(array: any, key: string, reverse = false) {
  return array.sort((a: any, b: any) => {
    const x = parseFloat(a[key])
    const y = parseFloat(b[key])

    if (x < y) return reverse ? 1 : -1;
    if (x > y) return reverse ? -1 : 1;
    return 0;
  });
}

export function redirectToHome(){
  const HomeAppURL = urlProtocol(localStorage.getItem("HomeAppURL")||environment.redirectHomeBase)
  const redirectURL = btoa(unescape(encodeURIComponent(window.location.href)))
  window.open(`${HomeAppURL}/auth-redirect?redirectURL=${redirectURL}`,'_self')
}


