<div [ngClass]="{fullscreen: isFullscreen}">
  <div class="main-container">
    <div class="box">
    <div class="input-container">
      <p-autoComplete
        #acSearch
        styleClass="search-input-box"
        (input)="onInput($event)"
        (paste)="onPaste($event)"
        [(ngModel)]="querySearch"
        [completeOnFocus]="true"
        (keydown.enter)="search()"
        [suggestions]="results"
        (onFocus)="onSearchFocus()"
        (onSelect)="search()"
        (onKeyUp)="onSearchKeyUp()"
        [autoOptionFocus]="false"
        [overlayOptions]="{
          styleClass: 'zIndex-30 ' + overlayClass,
          autoZIndex: false,
        }">
        <ng-template pTemplate="header">
          <div class="recent-search-heading" *ngIf="isRecentQuery">Your recent searches</div>
        </ng-template>
        <ng-template let-suggestion pTemplate="item">
          <ng-container *ngIf="isRecentQuery" [ngTemplateOutlet]="suggestions" [ngTemplateOutletContext]="{'suggestion':suggestion}"></ng-container>
          <!-- Below code is part of search key suggestions as user types -->
          <!-- <ng-container *ngIf="!isRecentQuery" [ngTemplateOutlet]="searchResult" [ngTemplateOutletContext]="{'suggestion':suggestion}"></ng-container> -->
        </ng-template>

        <!-- Below code is for showing trending searches -->
        <!-- <ng-template pTemplate="footer">
          <div class="recent-search-footer" *ngIf="isRecentQuery">
            <div class="recent-search-footer-heading">Trending</div>
            <div class="recent-search-footer-trending-container">
              <div class="trending-footer-itm" *ngFor="let x of results">{{x}}</div>
            </div>
          </div>
        </ng-template> -->

      </p-autoComplete>
    </div>
    <div class="icon" (click)="search()"><ng-container [ngTemplateOutlet]="searchIcon"></ng-container>
    </div>
  </div>
    <div (click)="turnOffFullScreen()" *ngIf="isFullscreen" class="cancel-btn">Cancel</div>
  </div>
</div>

<ng-template #searchResult let-suggestion="suggestion">
  {{suggestion}}
</ng-template>

<ng-template #suggestions let-suggestion="suggestion">
  <div class="suggestion-container">
    <div class="suggestion-parent-container" (click)="onRecentSearchClick()">
      <div class="suggestion-box">
        <ng-container [ngTemplateOutlet]="recentSearchIcon"></ng-container>
        <div class="suggestion-text">{{suggestion}}</div>
      </div>
      <div class="remove-suggestion" (click)="removeCachedSuggestion(suggestion)">
        <img src="../../../../assets/icons/cross.svg" alt="">
      </div>
    </div>
    <div class="suggestion-line-break">
      <div class="suggestion-box" style="align-items: center">
        <div style="visibility: hidden;"><ng-container [ngTemplateOutlet]="recentSearchIcon"></ng-container></div>
        <div class="suggestion-line"></div>
      </div>
    </div>
  </div>
</ng-template>


<ng-template #searchIcon>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M21.4073 19.7527L16.9969 15.3422C18.0587 13.9286 18.6319 12.208 18.63 10.44C18.63 5.92406 14.9559 2.25 10.44 2.25C5.92406 2.25 2.25 5.92406 2.25 10.44C2.25 14.9559 5.92406 18.63 10.44 18.63C12.208 18.6319 13.9286 18.0587 15.3422 16.9969L19.7527 21.4073C19.9759 21.6069 20.2671 21.7135 20.5664 21.7051C20.8658 21.6967 21.1506 21.574 21.3623 21.3623C21.574 21.1506 21.6967 20.8658 21.7051 20.5664C21.7135 20.2671 21.6069 19.9759 21.4073 19.7527ZM4.59 10.44C4.59 9.28298 4.9331 8.15194 5.5759 7.18991C6.21871 6.22789 7.13235 5.47808 8.2013 5.03531C9.27025 4.59253 10.4465 4.47668 11.5813 4.70241C12.7161 4.92813 13.7584 5.48529 14.5766 6.30343C15.3947 7.12156 15.9519 8.16393 16.1776 9.29872C16.4033 10.4335 16.2875 11.6098 15.8447 12.6787C15.4019 13.7476 14.6521 14.6613 13.6901 15.3041C12.7281 15.9469 11.597 16.29 10.44 16.29C8.88906 16.2881 7.40217 15.6712 6.30548 14.5745C5.2088 13.4778 4.59186 11.9909 4.59 10.44Z" fill="var(--l-primary-tenant-button-text)"/>
  </svg>
</ng-template>

<ng-template #recentSearchIcon>
  <div class="suggestion-icon">
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path d="M11.2487 6.66667H9.9987V10.8333L13.5654 12.95L14.1654 11.9417L11.2487 10.2083V6.66667ZM10.832 2.5C8.84291 2.5 6.93525 3.29018 5.52873 4.6967C4.12221 6.10322 3.33203 8.01088 3.33203 10H0.832031L4.13203 13.3583L7.4987 10H4.9987C4.9987 8.4529 5.61328 6.96917 6.70724 5.87521C7.8012 4.78125 9.28493 4.16667 10.832 4.16667C12.3791 4.16667 13.8629 4.78125 14.9568 5.87521C16.0508 6.96917 16.6654 8.4529 16.6654 10C16.6654 11.5471 16.0508 13.0308 14.9568 14.1248C13.8629 15.2188 12.3791 15.8333 10.832 15.8333C9.2237 15.8333 7.76536 15.175 6.71536 14.1167L5.53203 15.3C6.22493 16.0005 7.05051 16.5559 7.96053 16.9336C8.87055 17.3114 9.84674 17.5039 10.832 17.5C12.8212 17.5 14.7288 16.7098 16.1353 15.3033C17.5419 13.8968 18.332 11.9891 18.332 10C18.332 8.01088 17.5419 6.10322 16.1353 4.6967C14.7288 3.29018 12.8212 2.5 10.832 2.5Z" fill="black"/>
    </svg>
  </div>
</ng-template>


