// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { commonEnvConfig } from "./commonConfig";

export const environment = {
  ...commonEnvConfig,
  app:'dev-shop',
  isCookieSecure: true,
  production: false,
  apiUri: "https://9oma2mjz24.execute-api.us-east-1.amazonaws.com/dev",
  s3AssetUrl: "https://dev-laasy-web-app-bucket.s3.amazonaws.com",
  s3MerchantLogoUrl: "https://dev-laasy-resources.s3.amazonaws.com/Merchant_Logos/",
  browserMonitorID:'1386180392',//new Relic
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
