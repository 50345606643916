import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  private querySubject = new BehaviorSubject<string>('');
  onSearch = new Subject<string>();

  setQuery(query: string): void {
    this.querySubject.next(query);
  }

  getQuery(): Subject<string> {
    return this.querySubject;
  }
}
