import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchSectionService } from '../../services/search/search-section.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { RoundCard } from '../../models/landing';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { MenuItem } from 'primeng/api';
import { TypeOfSearch } from '@laasy/common-layout/shop';

@Component({
  selector: 'app-category',
  standalone: true,
  imports: [CommonModule, TieredMenuModule],
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit{

  categoryitems!: MenuItem[];
  @Output() onCategoryClick = new EventEmitter<{ label: string, source: string }>();
  skeletonLoader_isDataLoaded = {
    categories : false,
  }
  skeletonLoader_dataLoadedMap = {
    categories : 'categories',
  }
  subs !: Subscription[];

  constructor( public searchSecService: SearchSectionService) {}

  ngOnInit():void {
    this.subs = [
      this.searchSecService.getCategories().subscribe(),
      this.searchSecService.shopCategories$.subscribe({
        next: (data: any) => {
          this.categoryitems = data.dropDown.map((x: any) => this.mapToMenuItem(x))
          this.hide_skeletonLoader(this.skeletonLoader_dataLoadedMap.categories);
        }
      }),
    ]
  }

  hide_skeletonLoader(key:string):void{
    const loaderKey = key as keyof typeof this.skeletonLoader_isDataLoaded;
    setTimeout(() => this.skeletonLoader_isDataLoaded[loaderKey] = true, 300)
  }

  mapToMenuItem(item: any): MenuItem {
    const menuItem: MenuItem = {
      label: item.label,
      items: item.items
    };
    if (item.items && item.items.length > 0) {
      menuItem.items = item.items.map((child: any) => this.mapToMenuItem(child));
    } else {
      menuItem.command = () => {
        this.onCategoryClick.emit({ label: item.label, source: TypeOfSearch.SourceDropdown })
      };
    }

    return menuItem;
  }
}
