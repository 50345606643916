import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TruncatePipe } from '../../pipes/truncate.pipe';

@Component({
  selector: 'app-empty-state-display',
  standalone: true,
  imports: [CommonModule, TruncatePipe],
  templateUrl: './empty-state-display.component.html',
  styleUrls: ['./empty-state-display.component.scss']
})
export class EmptyStateDisplayComponent {
  @Input() searchQuery !: string;
}
