import { Component, EventEmitter, HostListener, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { SearchComponent } from '../search/search.component';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { urlProtocol } from '@laasy/common-layout';
import { environment } from 'src/environments/environment';
import { collectionResultPage } from '../../utilities/constants';
import { MenuItem } from 'primeng/api';
import { SearchSectionService } from '../../services/search/search-section.service';
import { Subscription } from 'rxjs';
import { HowItWorksComponent, TypeOfSearch } from '@laasy/common-layout/shop';
import { SkeletonModule } from 'primeng/skeleton';
import { DeviceTypeDirective } from '@laasy/common-layout';
import { CategoryComponent } from "../category/category.component";
import { GoogleCategoryComponent } from '../google-category/category.component';

@Component({
  selector: 'app-search-section',
  standalone: true,
  imports: [CommonModule, TieredMenuModule, SearchComponent, DialogModule, ButtonModule, HowItWorksComponent, SkeletonModule, DeviceTypeDirective, CategoryComponent,GoogleCategoryComponent],
  templateUrl: './search-section.component.html',
  styleUrls: ['./search-section.component.scss']
})
export class SearchSectionComponent implements OnInit, OnDestroy {
  @Output() onCategoryClick = new EventEmitter<{ label: string, source: string }>();
  showHighestCashback = false;
  showBestSellers = false;
  isMobile = false
  isTablet = false
  showHelpModal = false
  isGoogleCat = false
  subs !: Subscription[];
  skeletonLoader_isDataLoaded = {
    bestSellers: false,
    categories : false,
    highestCashback: false,
  }
  skeletonLoader_dataLoadedMap = {
    bestSellers: 'bestSellers',
    categories : 'categories',
    highestCashback: 'highestCashback',
  }

  constructor(
    private router: Router,
    public searchSecService: SearchSectionService,
  ) {}

  ngOnInit(): void {
    this.onWindowResize()
    this.subs = [
      this.searchSecService.getCategories().subscribe(),
      this.searchSecService.shopCategories$.subscribe({
        next: (data: any) => {
          this.isGoogleCat = data?.isGoogleCat
          this.hide_skeletonLoader(this.skeletonLoader_dataLoadedMap.categories);
      }}),
      this.searchSecService.showLabel.subscribe({
        next: ({showHighestCashback,showBestSellers}) => {
          this.showHighestCashback = showHighestCashback;
          this.showBestSellers = showBestSellers;
          this.hide_skeletonLoader(this.skeletonLoader_dataLoadedMap.bestSellers);
          this.hide_skeletonLoader(this.skeletonLoader_dataLoadedMap.highestCashback)
        }
      })
    ]
  }

  hide_skeletonLoader(key:string):void{
    const loaderKey = key as keyof typeof this.skeletonLoader_isDataLoaded;
    setTimeout(() => this.skeletonLoader_isDataLoaded[loaderKey] = true, 300)
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub?.unsubscribe());
  }

  mapToMenuItem(item: any): MenuItem {
    const menuItem: MenuItem = {
      label: item.label,
      items: item.items
    };
    if (item.items && item.items.length > 0) {
      menuItem.items = item.items.map((child: any) => this.mapToMenuItem(child));
    } else {
      menuItem.command = () => {
        this.onCategoryClick.emit({ label: item.label, source: TypeOfSearch.SourceDropdown })
      };
    }

    return menuItem;
  }

  toogleHelpModal() {
    this.showHelpModal = !this.showHelpModal
  }

  @HostListener('window:resize', ['$event'])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onWindowResize(): void {
    this.isTablet = window.innerWidth < 960;
    this.isMobile = window.innerWidth < 600;
  }

  openSavedItems() {
    window.location.href = urlProtocol(localStorage.getItem("HomeAppURL") || environment.redirectHomeBase) + '/account/saved'
  }

  openHighestCashbackItems(): void {
    this.router.navigateByUrl('/collections/'+collectionResultPage['highestcashback'].route);
  }

  openBestSellerItems(): void {
    this.router.navigateByUrl('/collections/'+collectionResultPage['bestseller'].route);
  }
}
