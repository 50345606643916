import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuModule } from 'primeng/menu';
import { CheckboxModule } from 'primeng/checkbox';
import { SortBy } from '../../models/sortBy';

@Component({
  selector: 'app-sort-by',
  standalone: true,
  imports: [CommonModule,MenuModule,CheckboxModule],
  templateUrl: './sort-by.component.html',
  styleUrls: ['./sort-by.component.scss']
})
export class SortByComponent {

  @Input() items:any=[]
  @Output() selected = new EventEmitter<number>()
  @Output() selectedString = new EventEmitter<string>()

  currentSortBy=''
  sortByitems:any=[]

  constructor(private cdr:ChangeDetectorRef){}

  ngOnInit(): void {
    this.items.forEach((x: SortBy) => {
      this.addSortByitems(x.sortKey,x.id)
    })
    this.setSortBy(this.sortByitems[0].label,0)
  }

  setSortBy(label: string,index: number){
    this.currentSortBy = label
    this.sortByitems.forEach((itm: any) => {
      if (itm.label === label) itm.isSelected = true
      else itm.isSelected = false
    })
  }

  selectSortBy(label: string,index: number) {
    this.setSortBy(label,index);
    this.selectedString.emit(label)
    this.selected.emit(index)
    this.cdr.detectChanges()
  }

  addSortByitems(label: string,index: number) {
    this.sortByitems.push({ label: label, command: () => this.selectSortBy(label,index), isSelected: false })
  }

}
