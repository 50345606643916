import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, OnInit, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchSectionService } from '../../services/search/search-section.service';
import { Subscription } from 'rxjs';
import { MenuItem } from 'primeng/api';
import { TypeOfSearch } from '@laasy/common-layout/shop';
import { SidebarModule } from 'primeng/sidebar';
import { CategoryComp } from '../../utilities/constants';
import { DeviceTypeDirective } from '@laasy/common-layout';
import { sumBetweenIndicesOptimized, findGroupsWithSumLessThanX } from './category.utility';

@Component({
  selector: 'app-google-category',
  standalone: true,
  imports: [CommonModule, SidebarModule, DeviceTypeDirective],
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class GoogleCategoryComponent implements OnInit {

  consts = CategoryComp
  l1List!: MenuItem[];
  @Output() onCategoryClick = new EventEmitter<{ label: string, source: string }>();
  subs !: Subscription[];

  containerChildMenuColumns: any[] = []
  categoriesItemsHeight: number[] = []
  catPos: any = []
  catHeightAcc: any = []
  showCat = false
  showChildren = false
  isMultiLevel = false

  dropdownHTML: any[] = []
  menuContainerWidth = 500
  menuContainerHeight = (592 > window.innerHeight - 153) ? window.innerHeight - 153 : 592
  maxMenuContainerHeight = 592

  category: any[] = []
  activeParentIndex = -1
  maxWidthMenuItem = 100

  // mobile
  selectedLevels: string[] = []
  mobileDropdownItems: any = []
  mobileCurrentLevel = 0
  mobileHeader: string = this.consts.mobileHeader


  constructor(
    private searchSecService: SearchSectionService,
    private cdRef: ChangeDetectorRef,
    private elementRef: ElementRef
  ) { }

  ngOnInit(): void {
    this.subs = [
      this.searchSecService.shopCategories$.subscribe({
        next: (data: any) => {
          this.l1List = data.dropDown.map((x: any) => x.label)

          this.isMultiLevel = data.isGoogleCat
          // original ref
          this.category = data.dropDown
          // change based on user click
          this.mobileDropdownItems = data.dropDown
        }
      }),
    ]
  }

  @HostListener('document:click', ['$event.target'])
  onClickOutside(targetElement: HTMLElement) {
    const clickedInside = this.elementRef.nativeElement.contains(targetElement) || targetElement.closest('.category-click');
    if (!clickedInside) this.closeMenu()
  }

  ngAfterViewChecked(): void {
    const element = document.getElementById("app-search-section") as HTMLElement
    const categoryMenu = element.getElementsByClassName('categories search-section-menu')[0]?.getBoundingClientRect();
    const SavedButton = element.getElementsByClassName('saved search-section-menu')[0]?.getBoundingClientRect();
    const howItWorksButton = element.getElementsByClassName('help search-section-menu')[0]?.getBoundingClientRect();
    if (window.innerWidth > 1400)
      this.menuContainerWidth = SavedButton?.right - categoryMenu?.left
    else
      this.menuContainerWidth = howItWorksButton?.right - categoryMenu?.left

    if (!this.menuContainerWidth || this.menuContainerWidth < 900) this.menuContainerWidth = window.innerWidth * 0.95

    this.maxWidthMenuItem = this.menuContainerWidth / 5 - 20
    this.cdRef.detectChanges()
  }

  getL2L3Labels(parentIndex: number): string[] {
    if (!this.category.length || window.innerWidth < 960) return []
    let level_2_3_labels: string[] = []
    this.catPos = []
    // check for cache
    if (false) return []
    this.category[parentIndex].children.forEach((l2: any) => {
      this.catPos.push({ name: l2.label, pos: level_2_3_labels.length })
      level_2_3_labels.push(l2.label)
      const l3 = l2.children
      level_2_3_labels = level_2_3_labels.concat(l3.map((x: any) => x.label))
    })
    return level_2_3_labels
  }


  setCategoryItemHeight(menuLabels: string[]) {
    const canvas: any = document.getElementById("myCanvas");
    const ctx = canvas.getContext("2d");
    const menuFont = document.querySelector('.menu-font');
    if (menuFont) {
      const computedStyle = getComputedStyle(menuFont);
      ctx.font = `${computedStyle.fontSize} ${computedStyle.fontFamily}`;
      ctx.textBaseline = 'top';
      const lineHeight = 19.6;
      const x = 10;
      let y = 10;

      menuLabels.forEach((txt: string) => {
        const lines = this.wrapText(ctx, txt, this.maxWidthMenuItem);
        const totalHeight = lines.length * lineHeight;
        this.categoriesItemsHeight.push(totalHeight);

        lines.forEach((line) => {
          ctx.fillText(line, x, y);
          y += lineHeight;
        });
      });
    }
  }

  wrapText(ctx: CanvasRenderingContext2D, text: string, maxWidth: number): string[] {
    const words = text.split(' ');
    let line = '';
    const lines: string[] = [];

    words.forEach((word) => {
      const testLine = line + (line ? ' ' : '') + word;
      const metrics = ctx.measureText(testLine);
      const testWidth = metrics.width;

      if (testWidth > maxWidth && line) {
        lines.push(line);
        line = word; // Start a new line
      } else {
        line = testLine; // Keep adding to the current line
      }
    });

    if (line) lines.push(line); // Push any remaining text

    return lines;
  }

  generateMenuColumnGroups(parentIndex: number) {
    if (!this.category.length) return
    for (let i = 0; i < this.containerChildMenuColumns.length; i++) {
      this.containerChildMenuColumns[i] = [];
    }

    const indexCat = this.catPos.map((x: any) => x.pos)
    indexCat.push(this.categoriesItemsHeight.length - 1)
    const sumHeightCat = sumBetweenIndicesOptimized(this.categoriesItemsHeight, indexCat)
    const indexGroup = findGroupsWithSumLessThanX(sumHeightCat, this.maxMenuContainerHeight)
    indexGroup.forEach((x: any, i: number) => {
      const tmp: any[] = []
      x.forEach((y: any) => {
        const catName = this.catPos[y].name
        tmp.push({ label: catName, type: 'heading' })
        const level1 = this.category[parentIndex]?.children
        const level2 = level1.find((x: any) => x.label === catName)?.children
        level2.forEach((z: any) => tmp.push({ ...z, type: 'leaf' }))
      })

      this.containerChildMenuColumns[i] = tmp
    })
    this.cdRef.detectChanges()
  }

  openMenu() {
    this.showCat = true
  }

  closeMenu() {
    this.showCat = false
    this.showChildren = false
  }

  toggleChild(parentIndex: number) {
    this.activeParentIndex = parentIndex
    const level_2_3_labels = this.getL2L3Labels(parentIndex)
    this.setCategoryItemHeight(level_2_3_labels)
    this.generateMenuColumnGroups(parentIndex)
    this.showChildren = true
  }

  levelOne(label: any) {
    if (!this.isMultiLevel) {
      this.onCategoryClick.emit({ label: label, source: TypeOfSearch.SourceDropdown })
      this.closeMenu()
    }
  }

  isLastItem(i: number, dropdownItems: any) {
    if (i > 0 && i < dropdownItems.length - 1) {
      return dropdownItems[i + 1].type === 'heading'
    }
    return false
  }

  mobileLastDisplayLevel() {
    return this.mobileCurrentLevel === this.consts.maxDisplayLevel - 1
  }

  mobileMenuClick(item: any) {
    const label = item.label;
    if (!this.isMultiLevel) {
      this.levelOne(label)
      this.closeMenu()
      return
    }
    this.selectedLevels[this.mobileCurrentLevel] = label;
    let currentCategory = this.category;
    if (this.mobileLastDisplayLevel()) {
      // route
      console.log(this.selectedLevels)
      return
    }

    for (let level = 0; level <= this.mobileCurrentLevel; level++) {
      const selectedItem = this.selectedLevels[level];
      const category = currentCategory.find((x: any) => x.label === selectedItem);
      if (category?.children?.length) {
        this.mobileDropdownItems = category.children;
        currentCategory = category.children;
        this.mobileHeader = selectedItem
      }
    }

    this.mobileCurrentLevel++;
  }

  mobileBack() {
    // Clear the current level and move back
    this.mobileCurrentLevel--;
    this.selectedLevels[this.mobileCurrentLevel] = '';

    if (this.mobileCurrentLevel === 0) {
      this.mobileDropdownItems = this.category;
      this.mobileHeader = this.consts.mobileHeader;
    } else {
      let currentCategory = this.category;
      let header = this.consts.mobileHeader;

      for (let i = 0; i < this.mobileCurrentLevel; i++) {
        const selectedLabel = this.selectedLevels[i];
        const foundCategory = currentCategory.find((x: any) => x.label === selectedLabel);
        if (foundCategory) {
          header = foundCategory.label;
          currentCategory = foundCategory.children || [];
        } else {
          currentCategory = [];
          break;
        }
      }

      this.mobileHeader = header;
      this.mobileDropdownItems = currentCategory;
    }
  }

}
