import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-bottom-sheet',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './bottom-sheet.component.html',
  styleUrls: ['./bottom-sheet.component.scss']
})
export class BottomSheetComponent{

  protected bottomPosition = '-100%';
  protected showSheet = false;
  
  open(){
    this.showSheet = true;
    setTimeout(() => this.bottomPosition = '0', 0)
  }

  close(){
    this.bottomPosition = `-100%`;
    setTimeout(() => this.showSheet = false, 210);
  }

  protected onBackdropClick(event: MouseEvent){
    if((event?.target as any)?.dataset?.sheetcontainer)
      this.close();
  }
}
