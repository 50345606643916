import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate',
  standalone: true
})
export class TruncatePipe implements PipeTransform {

  transform(str: string, n = 15): string {
    if(str.length > n){
      return str.substring(0,n).trim() + '...';
    }
    return str;
  }

}
