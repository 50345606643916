<div class="categories search-section-menu">
  <div class="categories" (click)="openMenu()">
    <div class="text">Categories</div>
    <div class="dropicon">
      <img src="../../../../assets/icons/downarrow.svg" alt="">
    </div>
  </div>
</div>

<div class="l-menu menu-font"  *appDeviceType="'desktop'">
  <ng-container [ngTemplateOutlet]="parentMenu" *ngIf="showCat && !isMultiLevel"></ng-container>
  <ng-container [ngTemplateOutlet]="multiLevel" *ngIf="showCat && isMultiLevel"></ng-container>
</div>

<ng-container [ngTemplateOutlet]="mobileMultiLevel" *appDeviceType="'mobile'" ></ng-container>

<ng-template #multiLevel>
  <div class="multi-level-menu" [ngStyle]="{'width':menuContainerWidth+'px','height':menuContainerHeight+'px'}">
    <div class="menu-container">
      <ng-container [ngTemplateOutlet]="parentMenu"></ng-container>
      <div class="child-menu-container no-scrollbar">
        <ng-container [ngTemplateOutlet]="childMenu"
          [ngTemplateOutletContext]="{dropdownItems:containerChildMenuColumns[0]}"></ng-container>
        <ng-container [ngTemplateOutlet]="childMenu"
          [ngTemplateOutletContext]="{dropdownItems:containerChildMenuColumns[1]}"></ng-container>
        <ng-container [ngTemplateOutlet]="childMenu"
          [ngTemplateOutletContext]="{dropdownItems:containerChildMenuColumns[2]}"></ng-container>
        <ng-container [ngTemplateOutlet]="childMenu"
          [ngTemplateOutletContext]="{dropdownItems:containerChildMenuColumns[3]}"></ng-container>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #parentMenu>
  <div class="parent-container no-scrollbar" [ngClass]="{'br-1':isMultiLevel}">
    <div class="parent-menu parent-menu-items" [ngClass]="{'hover-active':activeParentIndex == i && isMultiLevel}"
      *ngFor="let text of l1List; let i = index" (mouseenter)="toggleChild(i)" (click)="levelOne(text)">
      <div>{{text}}</div>
      <img *ngIf="isMultiLevel" src="../../../../assets/icons/right-arrow.svg" alt="">
    </div>
  </div>
</ng-template>


<ng-template #childMenu let-dropdownItems="dropdownItems">
  <div *ngIf="showCat && showChildren" class="menu-child-container">
    <div *ngFor="let item of dropdownItems; let i = index" class="menu-child-items"
      [ngClass]="{'dropdownHeading':item.type==='heading'}">
      {{item.label}}
      <div style="margin-bottom: 12px;" *ngIf="isLastItem(i,dropdownItems)"></div>
    </div>
  </div>
</ng-template>


<ng-template #mobileMultiLevel>
  <p-sidebar [(visible)]="showCat" position="bottom">
    <ng-template pTemplate="header">
      <div class="back category-click" (click)="mobileBack()" *ngIf="mobileHeader !== consts.mobileHeader"><img src="../../../../assets/icons/arrow-left.svg" ></div>
      <div class="mobile-menu-header" style="height: auto;" *ngIf="mobileHeader === consts.mobileHeader">{{mobileHeader}}</div>
    </ng-template>

    <div class="content  menu-font">
      <div class="mobile-menu-header" *ngIf="mobileHeader !== consts.mobileHeader">{{mobileHeader}}</div>
      <div class="mobile-menu-items category-click" *ngFor="let item of mobileDropdownItems; let i = index" (mouseenter)="toggleChild(i)"
        (click)="mobileMenuClick(item)">
        <div>{{item.label}}</div>
        <img *ngIf="isMultiLevel && !mobileLastDisplayLevel()" src="../../../../assets/icons/right-arrow.svg" alt="">
      </div>
    </div>
  </p-sidebar>
</ng-template>



<!-- used to calculate height of each items in 2nd and 3rd level items -->
<canvas id="myCanvas" width="300" height="150" style="display: none;"></canvas>
<div class="menu-font"></div>
